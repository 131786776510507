import styled, { css } from 'styled-components';

const getStatusDisplayName = state => {
  switch (state) {
    case 'rejected':
      return 'Rejetée';
    case 'submitted':
      return 'Neuf';
    case 'waiting':
      return 'En attente';
    case 'contested':
      return 'Contestée';
    case 'increased':
      return 'Prolongée';
    case 'to_pay':
      return 'À valider';
    case 'paid':
      return 'Payée';
    case 'paid_with_equivalent':
      return 'Payée (autre)';
    default:
      return '—';
  }
};

const Wrapper = styled.span`
  background-color: ${({ theme }) => theme.primaryBackground};
  border-radius: 4px;
  color: ${({ theme }) => theme.primary};
  ${'' /* font-size: ${({ theme }) => theme.size(-1)}; */}
  padding: ${({ theme }) => theme.spacing(0.25)} ${({ theme }) =>
  theme.spacing(0.5)};
  margin-right: ${({ theme }) => theme.spacing(0.5)};
  white-space: nowrap;

  ${({ variant }) =>
    (variant === 'contested' || variant === 'rejected') &&
    css`
      color: #e22a2a;
      background-color: rgba(226, 42, 42, 0.14);
    `}
`;

export const InvoiceStatusTag = ({ state, rejected }) => {
  if (!state && !rejected) {
    return null;
  }

  return <Wrapper variant={state}>{getStatusDisplayName(state)}</Wrapper>;
};

export default InvoiceStatusTag;
