import styled, { css } from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { ButtonReset } from '@tymate/margaret';

export const Input = styled.input`
  font-size: inherit;
  padding: ${({ theme }) => theme.spacing(0.75)}
    ${({ theme }) => theme.spacing()};
  border: 1px solid ${({ theme }) => theme.separator};
  flex: 1;
  border-radius: 8px;
  transition: border-color 100ms ease;
  height: 48px;
  background-color: #fff;
  width: 100%;

  ${({ type }) =>
    type === 'password' &&
    css`
      padding-right: 44px;
    `}

  &:not([disabled]):focus,
  &:not([disabled]):active {
    outline: none;
    border: 1px solid ${({ theme }) => theme.separator};
  }

  &:disabled {
    background: ${({ theme }) => theme.disabledBackgroundLight};
    color: ${({ theme }) => theme.disabledColor};
  }

  ::placeholder {
    color: ${({ theme }) => theme.textLighter};
  }

  ${({ variant }) =>
    variant === 'input' &&
    css`
      border-radius: 4px 0 0 4px;
      border-right: none;
      border: 1px solid ${({ theme }) => theme.primary};

      &:not([disabled]):focus,
      &:not([disabled]):active {
        outline: none;
        background-color: #feeec9;
        border: 1px solid ${({ theme }) => theme.primary};
      }
    `}

  ${({ hasError }) =>
    hasError &&
    css`
      &,
      &:hover,
      &:active {
        border: 1px solid ${({ theme }) => theme.error};
      }
    `}

  ${({ variant }) =>
    variant === 'bare' &&
    css`
      border: 0;
      height: 46px;

      &:not([disabled]):focus,
      &:not([disabled]):active {
        outline: none;
        border: 0;
      }
    `}

  ${({ hasSuffix }) =>
    hasSuffix &&
    css`
      padding-right: ${({ theme }) => theme.spacing(2)};
    `};
`;

export const Textarea = styled(TextareaAutosize)`
  padding: ${({ theme }) => theme.spacing(0.875)}
    ${({ theme }) => theme.spacing()};
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: 6px;
  color: ${({ theme }) => theme.text};
  font-weight: 400;
  outline: none;
  width: 100%;
  resize: none;
  background-color: #fff;

  &:focus,
  &:active {
    border-color: ${({ theme }) => theme.darkSeparator};
  }

  ::placeholder {
    color: ${({ theme }) => theme.textLighter};
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
    color: inherit;
  }

  &:disabled {
    background-color: rgba(0, 40, 100, 0.04);
    cursor: not-allowed;
  }

  ${({ hasError }) =>
    hasError &&
    css`
      &,
      &:active,
      &:focus {
        border-color: ${({ theme }) => theme.error};
      }
    `}
`;

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  + * {
    margin-top: ${({ theme }) => theme.spacing(1.5)};
  }

  ::placeholder {
    color: ${({ theme }) => theme.textLighter};
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
    color: inherit;
  }

  &:disabled {
    background-color: rgba(0, 40, 100, 0.04);
    cursor: not-allowed;
  }

  ${({ hasError }) =>
    hasError &&
    css`
      &,
      &:active,
      &:focus {
        border-color: ${({ theme }) => theme.error};
      }
    `}

  ${({ variant }) =>
    variant === 'stacked' &&
    css`
      + * {
        margin-top: ${({ theme }) => theme.spacing(0.5)};
      }
    `};
`;

export const SearchInput = styled(Input)`
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(2)};
  border-radius: 8px;
`;

export const FormLabel = styled.label`
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing(0.25)};
  text-align: left;
  color: ${({ theme }) => theme.text};
  width: 100%;
  font-size: 16px;
  font-weight: 600;
`;

export const ToggleFormLabel = styled(FormLabel)`
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;

  ${({ disabled }) =>
    disabled &&
    `
      cursor: not-allowed;
    `}
`;

export const InputSearch = styled(Input)`
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(2)};
  border-radius: 4px;
`;

export const InputSuffix = styled.span`
  display: flex;
  align-items: center;
  position: absolute;
  height: 100%;
  top: 0;
  right: ${({ theme }) => theme.spacing(0.5)};
  color: ${({ theme }) => theme.textLighter};
`;

export const ErrorMessage = styled.span`
  display: block;
  padding-top: ${({ theme }) => theme.spacing(0.5)};
  color: ${({ theme }) => theme.error};
  font-size: ${({ theme }) => theme.size(-1)};
`;

export const FormSelect = styled.select`
  appearance: none;
  -webkit-border-radius: 2px;
  -webkit-padding-end: 20px;
  -webkit-padding-start: 2px;
  -webkit-user-select: none;
  display: inline-block;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(0.75)}
    ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(0.75)}
    ${({ theme }) => theme.spacing()};
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.disabled};
  border-radius: 6px;
  color: inherit;
  font-size: ${({ theme }) => theme.size()};
  outline: none;

  &:focus,
  &:active {
    border-color: ${({ theme }) => theme.primaryVibrant};
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
    color: inherit;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.disabled};
  }
`;

export const SelectInput = styled.div`
  position: relative;

  > svg {
    position: absolute;
    top: 12px;
    right: 8px;
    z-index: 1;
    fill: ${({ theme }) => theme.textLighter};
  }
`;

export const TextFieldIcon = styled(ButtonReset)`
  position: absolute;
  top: 50%;
  right: ${({ theme }) => theme.spacing()};
  transform: translateY(-50%);

  ${({ variant }) =>
    variant === 'error' &&
    css`
      color: ${({ theme }) => theme.error};
    `}
`;
