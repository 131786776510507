import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';

const API_BASE = process.env.REACT_APP_API_BASE;

const client = new ApolloClient({
  uri: `${API_BASE}/graphql`,
  request: operation => {
    const state = JSON.parse(localStorage.getItem('state'));
    const accessToken = localStorage.getItem('accessToken');

    const hasToken =
      Boolean(accessToken) ||
      Boolean(state?.user?.accessToken) ||
      Boolean(state?.user?.impersonatedUser?.accessToken);

    if (hasToken) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${accessToken ||
            state?.user?.impersonatedUser?.accessToken ||
            state.user.accessToken}`,
        },
      });
    }
  },
});

client.defaultOptions = {
  query: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
};

const GraphQLProvider = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);

export default GraphQLProvider;
