import { createContext, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { get } from 'lodash';

const AppContext = createContext();

export const useApp = () => {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error(`useApp must be used within an AppProvider`);
  }

  return context;
};

const GET_COUNTERS_VALUES = gql`
  query GetCounters {
    myAgenciesApe(filter: { approved: { eq: false } }) {
      totalCount
    }
    allProvidersConnection(filter: { approved: { eq: false } }) {
      totalCount
    }
  }
`;

const AppProvider = ({ children }) => {
  const { data, refetch } = useQuery(GET_COUNTERS_VALUES);
  const counters = {
    ape: get(data, 'myAgenciesApe.totalCount'),
    providers: get(data, 'allProvidersConnection.totalCount'),
  };

  return (
    <AppContext.Provider
      value={{
        counters,
        updateCounters: refetch,
        countersQuery: GET_COUNTERS_VALUES,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
