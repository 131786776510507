import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { createGlobalStyle } from 'styled-components';
import ApolloClient from 'apollo-boost';
import { Router, Switch, Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { createBrowserHistory } from 'history';
import AuthProvider from 'containers/AuthProvider';
import { theme } from 'ui';
import App from './App';
import Auth from 'containers/Auth';
import { promiseToObservable } from 'utils';
import 'sanitize.css';
import * as serviceWorker from './serviceWorker';
import { API_BASE } from './constants';
import { refreshToken } from 'containers/Auth';
import { deleteFromStorage } from '@rehooks/local-storage';
import { MargaretProvider } from '@tymate/margaret';

const GlobalStyles = createGlobalStyle`
  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    background-color: ${({ theme }) => theme.background};
    color:${({ theme }) => theme.text};
  }
`;

const client = new ApolloClient({
  uri: `${API_BASE}/graphql`,
  request: operation => {
    const token = localStorage.getItem('accessToken');
    operation.setContext({
      headers: {
        authorization: Boolean(token) ? `Bearer ${token}` : null,
      },
    });
  },
  onError: ({ networkError, operation, forward }) => {
    const token = localStorage.getItem('refreshToken');

    if (networkError && networkError.statusCode === 401) {
      return promiseToObservable(refreshToken(token)).flatMap(response => {
        if (response.error) {
          deleteFromStorage('accessToken');
          deleteFromStorage('refreshToken');

          return forward(operation);
        }

        localStorage.setItem('accessToken', response.access_token);
        localStorage.setItem('refreshToken', response.refresh_token);
        operation.setContext({ retried: true });

        return forward(operation);
      });
    }
  },
});

export const history = createBrowserHistory();

ReactDOM.render(
  <Suspense fallback={<div />}>
    <Router history={history}>
      <ApolloProvider client={client}>
        <MargaretProvider theme={theme}>
          <AuthProvider>
            <GlobalStyles />
            <Switch>
              <Route path="/connexion" component={Auth} />
              <Route component={App} />
            </Switch>
          </AuthProvider>
        </MargaretProvider>
      </ApolloProvider>
    </Router>
  </Suspense>,
  document.getElementById('root'),
);

serviceWorker.unregister();
