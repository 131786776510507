import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

export const useSearchParams = () => {
  const location = useLocation();

  try {
    return queryString.parse(location.search.replace('?', ''));
  } catch (err) {
    console.log(err);
    return {};
  }
};
