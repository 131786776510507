import { lazy, useEffect } from 'react';
import { createGlobalStyle } from 'styled-components';
import { Switch, Route, Redirect } from 'react-router-dom';
import MainHeader from 'components/MainHeader';
import { useAuth } from 'containers/AuthProvider';
import AppProvider from 'containers/AppProvider';
import { history } from 'index';

const Invoices = lazy(() => import('containers/Invoices'));
const Providers = lazy(() => import('containers/Providers'));

const GlobalStyles = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.background}
  }

  #root {
    position: relative;
  }
`;

const App = () => {
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    if (!isLoggedIn) {
      history.push('/connexion');
    }
  }, [isLoggedIn]);

  return (
    <AppProvider>
      <GlobalStyles />

      <MainHeader />

      <Switch>
        <Route path="/facturation" component={Invoices} />
        <Route path="/fournisseurs" component={Providers} />
        <Redirect to="/facturation" />
      </Switch>
    </AppProvider>
  );
};

export default App;
