import { Component } from 'react';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import { TitleAndAction } from 'ui/lib';
import { Table, Thead, Tr, Th, Td, Tbody } from 'ui/lib/table';
import { get, isEqual, orderBy, pickBy } from 'lodash';
import { MdUnfoldMore, MdKeyboardArrowDown } from 'react-icons/md';
import LocationAwareSearch from './LocationAwareSearch';
import queryString from 'query-string';
import Spinner from './Spinner';

const ASC = 'asc';
const DESC = 'desc';

export const Title = styled.p`
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
`;

export const Preview = styled.p`
  color: ${({ theme }) => theme.textLight};
  margin-bottom: 0;
  margin-top: ${({ theme }) => theme.spacing(0.5)};
`;

const getInitialState = ({ headings, activeProp }) => {
  let state = {};

  if (activeProp) {
    state = {
      ...state,
      activeProp,
    };
  }

  // eslint-disable-next-line
  for (const prop of headings) {
    state = {
      ...state,
      propByKey: {
        ...state.propByKey,
        [prop.slug]: DESC,
      },
    };
  }

  return state;
};

const Content = ({ render, value }) =>
  render ? render() : <span>{value}</span>;

const SwitchButton = styled.button`
  border: 0;
  outline: none;
  cursor: ${({ filterable, fixed }) =>
    filterable && !fixed ? 'pointer' : 'normal'};
  padding: 0;
  font-size: inherit;
  text-transform: inherit;
  display: flex;
  align-items: center;
  background-color: transparent;
  white-space: nowrap;
  display: block;
  width: 100%;
  text-align: inherit;
  text-decoration: none;
  display: flex;
  color: ${({ theme }) => theme.text};

  svg {
    margin-right: 0;
    transform: ${props => (props.desc ? 'none' : 'rotate(180deg)')};
    transition: transform 150ms ease;
  }
`;

export class DataTable extends Component {
  constructor(props) {
    super(props);

    this.state = getInitialState(props);
  }

  componentDidMount() {
    Boolean(this.props.onReorder) && this.sendIdsToParent();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.state, prevState)) {
      Boolean(this.props.onReorder) && this.sendIdsToParent();
    }
  }

  sendIdsToParent = () => {
    const { activeProp, propByKey } = this.state;
    const nested = item => item[activeProp].value;

    const orderedIds = (
      (activeProp &&
        Boolean(this.props.data.length) &&
        orderBy(this.props.data, nested, propByKey[activeProp])) ||
      this.props.data
    ).map(({ id }) => id);

    this.props.onReorder(orderedIds);
  };

  orderBy = (prop, fixed) => {
    const { filterable } = this.props;
    if (!filterable || fixed) return;

    this.props.onOrderBy(prop);
  };

  togglePropOrder = prop => {
    const { propByKey } = this.state;

    if (propByKey[prop] === ASC) {
      this.setState({
        propByKey: {
          ...propByKey,
          [prop]: DESC,
        },
        activeProp: null,
      });

      return;
    }

    this.setState({
      propByKey: {
        ...propByKey,
        [prop]: propByKey[prop] === DESC ? ASC : DESC,
      },
    });
  };

  render() {
    const {
      headings,
      style,
      fixedLines,
      filterable,
      modal,
      prev,
      fixedHeader,
      loading,
      emptyState,
      location,
      isSearchable,
      history,
      actions,
      filters,
      isLoading,
    } = this.props;
    const { activeProp, propByKey } = this.state;
    const nested = item => item[activeProp].value;
    const sort = get(queryString.parse(location.search), 'sort');

    const data =
      (activeProp &&
        Boolean(this.props.data.length) &&
        orderBy(this.props.data, nested, propByKey[activeProp])) ||
      this.props.data;

    return (
      <>
        {(isSearchable || Boolean(actions)) && (
          <TitleAndAction>
            <div style={{ display: 'flex' }}>
              {isSearchable && <LocationAwareSearch history={history} />}
              {Boolean(filters) && filters}
              {isLoading && (
                <div style={{ marginLeft: 8, alignSelf: 'center' }}>
                  <Spinner />
                </div>
              )}
            </div>

            {Boolean(actions) && <div>{actions}</div>}
          </TitleAndAction>
        )}

        <Table variant="bordered" style={style} fixedHeader={fixedHeader}>
          <Thead fixedHeader={fixedHeader}>
            <Tr>
              {headings.map(
                ({
                  slug,
                  label,
                  fixed,
                  width,
                  textAlign,
                  hasNoLeftPadding,
                }) => (
                  <Th
                    key={slug}
                    style={{ width, textAlign }}
                    hasNoLeftPadding={hasNoLeftPadding}
                  >
                    {Boolean(label) && (
                      <SwitchButton
                        as={!fixed ? Link : null}
                        to={{
                          pathname: location.pathname,
                          search: queryString.stringify(
                            pickBy({
                              ...queryString.parse(location.search),
                              sort:
                                sort === `-${slug}`
                                  ? slug
                                  : sort === slug
                                  ? null
                                  : `-${slug}`,
                            }),
                          ),
                        }}
                        filterable={filterable}
                        fixed={fixed}
                        active={sort === slug || sort === `-${slug}`}
                        onClick={() => this.orderBy(slug, fixed)}
                        desc={sort === `-${slug}`}
                      >
                        {label}
                        {fixed ? null : sort === slug || sort === `-${slug}` ? (
                          <MdKeyboardArrowDown />
                        ) : (
                          <MdUnfoldMore />
                        )}
                      </SwitchButton>
                    )}
                  </Th>
                ),
              )}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((data, index) => (
              <Tr
                key={index}
                className={data.cls || ''}
                to={
                  data.path
                    ? {
                        pathname: data.path,
                        search: location.search,
                        state: { modal, prev },
                      }
                    : null
                }
                onClick={data.onClick}
              >
                {headings.map(
                  ({
                    slug,
                    className = '',
                    width,
                    nowrap,
                    textAlign,
                    hasNoHorizontalPadding,
                    hasNoLeftPadding,
                  }) => (
                    <Td
                      key={slug}
                      className={className}
                      nowrap={nowrap}
                      hasNoHorizontalPadding={hasNoHorizontalPadding}
                      hasNoLeftPadding={hasNoLeftPadding}
                      style={{ width, textAlign: textAlign || 'left' }}
                    >
                      <Content key={index} {...data[slug]} />
                    </Td>
                  ),
                )}
              </Tr>
            ))}
            {fixedLines.map((data, index) => (
              <Tr key={index} className={data.cls || ''}>
                {headings.map(({ slug }) => (
                  <Td key={slug}>
                    <Content key={index} {...data[slug]} />
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>

        {Boolean(emptyState) && data.length === 0 && !loading && emptyState}
      </>
    );
  }
}

DataTable.defaultProps = {
  headings: [],
  data: [],
  fixedLines: [],
  filterable: true,
  onOrderBy: () => null,
  sort: '',
  isSearchable: true,
};

export default withRouter(DataTable);
