import styled, { css } from 'styled-components';
import { NavLink as RawNavLink, Link } from 'react-router-dom';
import { spacing, size, theme } from './colors';
import { keys } from 'lodash';
import { useLocation } from 'react-use';

export { spacing, size, theme };

export const viewportSizes = {
  desktop: 1024,
  tablet: 800,
  tabletMini: 500,
};

const mediaQuery = (...query) => (...rules) =>
  css`
    @media ${css(...query)} {
      ${css(...rules)};
    }
  `;

export const media = {
  tabletMini: mediaQuery`(min-width: ${viewportSizes.tabletMini / 16}em)`,
  tablet: mediaQuery`(min-width: ${viewportSizes.tablet / 16}em)`,
  medium: mediaQuery`(min-width: ${viewportSizes.medium / 16}em)`,
  desktop: mediaQuery`(min-width: ${viewportSizes.desktop / 16}em)`,
};

export const Box = styled.div`
  background-color: #fff;
  border-radius: 16px;
  padding: ${props => (props.padded ? spacing(2) : props.bare ? 0 : spacing())};
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  color: #006694;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 0 ${({ theme }) => theme.spacing()};
  margin-left: auto;
  margin-right: auto;

  ${media.desktop`
    padding: 0 ${({ theme }) => theme.spacing(2)};
  `};

  ${props =>
    props.narrow &&
    css`
      max-width: 800px;
    `};

  ${props =>
    props.tight &&
    css`
      max-width: 540px;
    `};

  ${props =>
    props.left &&
    css`
      margin-left: 0;
    `};

  ${props =>
    props.center &&
    css`
      margin-left: auto;
      margin-right: auto;
    `};

  ${props =>
    props.bare &&
    css`
      padding-left: 0;
      padding-right: 0;
    `};

  ${props =>
    props.vertical &&
    css`
      padding-top: ${({ theme }) => theme.spacing()};
      padding-bottom: ${({ theme }) => theme.spacing()};
    `};

  ${props =>
    props.size === 'large' &&
    css`
      max-width: 1200px;
    `};

  ${props =>
    props.size === 'full' &&
    css`
      max-width: none;
    `};

  ${props =>
    props.followsHeader &&
    css`
      padding-top: 92px;
    `};
`;

export const List = styled.ul`
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
`;

export const InlineList = styled(List)`
  display: flex;
  flex-wrap: wrap;
`;

export const NavLink = styled(RawNavLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.primary};
`;

const generateToPropWithSearchIncluded = ({ search, to }) => {
  if (typeof to === 'object') {
    return {
      ...to,
      search,
    };
  }

  return {
    pathname: to,
    search,
  };
};

export const QueryParamsAwareLink = ({ to, ...props }) => {
  const { search } = useLocation();
  let linkTo = generateToPropWithSearchIncluded({ to, search });

  return <Link to={linkTo} {...props} />;
};

export const QueryParamsAwareNavLink = ({ to, ...props }) => {
  const { search } = useLocation();
  let linkTo = generateToPropWithSearchIncluded({ to, search });

  return <NavLink to={linkTo} {...props} />;
};

export const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: ${({ theme }) => theme.spacing(-1)};
  margin-top: ${({ theme }) => theme.spacing()};

  ${props =>
    props.marged &&
    css`
      padding-top: ${({ theme }) => theme.spacing()};
      padding-bottom: ${({ theme }) => theme.spacing()};
    `};

  ${props =>
    props.margedBottom &&
    css`
      padding-bottom: ${({ theme }) => theme.spacing()};
    `};

  ${props =>
    props.margedTop &&
    css`
      padding-top: ${({ theme }) => theme.spacing()};
    `};

  > * {
    margin-left: ${({ theme }) => theme.spacing()};
    margin-top: ${({ theme }) => theme.spacing()};
  }

  ${({ alignment }) =>
    alignment === 'left' &&
    css`
      justify-content: flex-start;
    `}
`;

export const LinkButton = styled.button`
  border: 0;
  padding: 0;
  text-decoration: ${props => (props.underlined ? 'underline' : 'none')};
  background-color: transparent;
  outline: none;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

export const Ul = styled(List)`
  margin-left: ${({ theme }) => theme.spacing(-1)};
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
`;

export const Li = styled.li`
  flex: 0 0 33.333%;
  padding-left: ${({ theme }) => theme.spacing()};
  padding-bottom: ${({ theme }) => theme.spacing()};
  display: flex;
  align-items: stretch;
`;

export const Title = styled.h2`
  font-size: ${size(2)};
  font-weight: 600;
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

export const Subtitle = styled.h3`
  font-size: ${size(1)};
  font-weight: 600;
  margin-top: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};

  ${props =>
    props.bareTop &&
    css`
      margin-top: 0;
    `};
`;

export const Description = styled.p`
  max-width: 45em;
  line-height: 1.5;
  white-space: pre-wrap;
`;

export const Card = styled.div`
  position: relative;
  background-color: #fff;
  padding: ${({ theme }) => theme.spacing(2)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  color: inherit;
  text-decoration: none;
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: 6px;

  ${props =>
    props.bare &&
    css`
      padding: 0;
    `};

  ${props =>
    props.variant === 'bordered' &&
    css`
      ${'' /* border: 1px solid ${ui('separator')}; */}
      border-radius: 6px;
    `};
`;

export const Cards = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

export const CardTitle = styled.h2`
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing(0.25)};
  font-size: 1.189rem;
  display: flex;
  align-items: center;

  ${props =>
    props.marged &&
    css`
      margin-top: ${({ theme }) => theme.spacing(0.25)};
      margin-bottom: ${({ theme }) => theme.spacing(0.5)};
    `};
`;

export const CardTitleAlt = styled(CardTitle)`
  text-transform: none;
  font-weight: 600;
`;

export const CardContent = styled.div`
  flex: 1;

  ${props =>
    props.bare &&
    css`
      padding-left: 0;
      padding-right: 0;
    `};
`;

export const CardActions = styled.div``;

export const CardMeta = styled.p`
  margin: 0;
  ${'' /* color: ${ui('textLight')}; */}
`;

export const ButtonReset = styled.button`
  font-size: inherit;
  appearance: none;
  outline: none;
  padding: 0;
  background: transparent;
  border: 0;
  text-align: left;
  cursor: pointer;

  ${props =>
    props.pointable &&
    css`
      cursor: pointer;
    `};

  ${props =>
    props.large &&
    css`
      font-size: ${size(1)};
    `};
`;

const Address = styled.div`
  color: rgba(25, 75, 105, 0.6);
`;

export const getAddress = address =>
  Boolean(address) ? (
    <Address>
      {address.streetNumber} {address.street}
      <br />
      {address.zipCode} {address.city}
    </Address>
  ) : (
    <span />
  );

export const SwitchListItem = styled.li`
  position: relative;
  padding: ${({ theme }) => theme.spacing(0.5)} 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: opacity 150ms ease;

  + li:before {
    position: absolute;
    content: '';
    display: block;
    left: ${({ theme }) => theme.spacing()};
    top: 0;
    right: 0;
    border-top: 1px solid #f2f4f7;
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.35;
    `};
`;

export const MediaImage = styled.div`
  display: flex;
`;

export const MediaBody = styled.div`
  flex: 1;
  padding-left: ${({ theme }) => theme.spacing()};
  word-break: break-word;

  ${props =>
    props.size === 'auto' &&
    css`
      flex: initial;
    `};

  ${props =>
    props.rtl &&
    css`
      padding-left: 0;
    `};

  ${({ variant }) =>
    variant === 'centered' &&
    css`
      flex: initial;
    `}
`;

export const Media = styled.div`
  display: flex;
  align-items: flex-start;

  ${props =>
    props.verticalAlign === 'center' &&
    css`
      align-items: center;
    `};

  ${props =>
    props.gutterSize === 'small' &&
    css`
      > ${MediaBody} {
        padding-left: ${({ theme }) => theme.spacing(0.5)};
      }
    `};

  ${({ variant }) =>
    variant === 'centered' &&
    `
      justify-content: center;
    `}
`;

export const Section = styled.section`
  margin-top: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  ${props =>
    props.padded &&
    css`
      padding: ${({ theme }) => theme.spacing()};
    `};

  ${props =>
    props.bareTop &&
    css`
      margin-top: 0;
    `};

  ${props =>
    props.semiBare &&
    css`
      margin-top: ${({ theme }) => theme.spacing()};
      margin-bottom: ${({ theme }) => theme.spacing()};
    `};
`;

export const TopBarWrapper = styled.div`
  position: static;
  padding: 0;
  display: flex;
  flex-direction: column;
  background-color: transparent;

  ${media.desktop`
    position: fixed;
    top: 0;
    left: 280px;
    right: 0;
    z-index: 2;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 2px 8px 0 ${({ theme }) => theme.separator};
    padding: ${({ theme }) => theme.spacing(1.25)}
    ${({ theme }) => theme.spacing(2)};
    height: 76px;
  `};

  ${props =>
    props.full &&
    css`
      right: 0;
    `};

  ${props =>
    props.followsLargeSidebar &&
    css`
      left: 320px;
    `};
`;

export const TopBarButtonWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing()} ${({ theme }) => theme.spacing()} 0;
  text-align: right;
  background-color: transparent;
  display: flex;
  align-items: center;

  ${media.desktop`
    padding: 0;
    position: static;
  `};

  ${({ variant, theme }) =>
    variant === 'spaced' &&
    `
    margin-left: ${theme.spacing(-1)};
    
    > span {
      margin-left: ${theme.spacing()};
    }
  `};

  ${({ variant, theme }) =>
    variant === 'nested' &&
    `
      padding: 0;
      margin-bottom: ${theme.spacing()};
    `};
`;

export const PublicWorkConcernLabel = styled.p`
  font-weight: 500;
  ${'' /* color: ${ui('success')}; */}
  font-size: ${size(1)};
`;

export const TitleAndAction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing()};
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.spacing(-0.5)};
  margin-left: ${({ theme }) => theme.spacing(-0.5)};

  > * {
    padding-top: ${({ theme }) => theme.spacing(0.5)};
    padding-left: ${({ theme }) => theme.spacing(0.5)};
  }

  ${props =>
    props.hasNoBottomMargin &&
    css`
      margin-bottom: 0;
    `};

  ${props =>
    props.hasTopSeparation &&
    css`
      margin-top: ${({ theme }) => theme.spacing()};
      margin-bottom: 0;
    `};

  > h3 {
    margin-bottom: 0;
  }
`;

export const ActionButton = styled(ButtonReset)`
  display: flex;
  ${'' /* background-color: ${ui('separator')}; */}
  border-radius: 100%;
  color: ${({ theme }) => theme.primary};
  font-size: ${size(2)};
  padding: ${({ theme }) => theme.spacing(0.25)};

  ${props =>
    props.isReadOnly &&
    css`
      background-color: #fff;
      cursor: initial;
    `};
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin-left: ${({ theme }) => theme.spacing(-1)};
  margin-top: ${({ theme }) => theme.spacing(-1)};

  > * {
    padding-left: ${({ theme }) => theme.spacing()};
    padding-top: ${({ theme }) => theme.spacing()};
  }

  ${props =>
    props.hasMarginBottom &&
    css`
      margin-bottom: ${({ theme }) => theme.spacing(1.5)};
    `};
`;

export const Cell = styled.div`
  ${props =>
    props.hasMarginLeft &&
    css`
      margin-left: ${({ theme }) => theme.spacing(1.5)};
    `} ${props =>
    props.sizes &&
    css`
      flex: 0 0 ${Math.floor(props.sizes.default * 10000) / 100}%;

      ${keys(props.sizes)
        .filter(key => key !== 'default')
        .map(
          breakpoint => css`
            ${media[breakpoint]`flex: 0 0 ${Math.floor(
              props.sizes[breakpoint] * 10000,
            ) / 100}%`};
          `,
        )};
    `};
`;

export const Legend = styled.span`
  color: ${({ theme }) => theme.textLight};
  ${'' /* font-size: ${({ theme }) => theme.size(-1)}; */}
`;

export const Trigger = styled.div`
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.5)};
  outline: none;
  color: inherit;
  position: relative;
  overflow: hidden;
  ${'' /* border: 1px solid ${ui('separator')}; */}
  border-radius: 4px;
  background-color: #fff;
  width: 100%;
  text-align: left;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-height: 40px;
  display: flex;
  align-items: center;
  font-size: ${size(1)};

  svg {
    position: absolute;
    top: 50%;
    right: ${({ theme }) => theme.spacing(0.5)};
    transform: translateY(-50%);
  }

  ${props =>
    props.hasIcon &&
    css`
      padding-left: ${({ theme }) => theme.spacing(2.25)};
    `};

  ${props =>
    props.disabled &&
    css`
      background-color: rgba(0, 0, 0, 0.05);
      cursor: not-allowed;
    `};
`;
