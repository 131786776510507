import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  0% {
    transform: rotate(0); 
  }
  100% {
    transform: rotate(360deg); 
  } 
`;

const Spinner = styled.div`
  height: ${({ size }) => (Boolean(size) ? `${size}px` : '1em')};
  width: ${({ size }) => (Boolean(size) ? `${size}px` : '1em')};
  color: currentColor;
  position: relative;
  display: inline-block;
  border: 0.15em solid;
  border-radius: 50%;
  border-top-color: transparent;
  animation: ${rotate} 1s linear infinite;
  margin-right: ${({ theme }) => theme.spacing(0.5)};
  box-sizing: border-box;
`;

export default Spinner;
