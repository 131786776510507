import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';

export const Wrapper = styled.button`
  font-size: inherit;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()};
  box-shadow: ${({ theme }) => theme.buttonBoxShadowHover};
  background: ${({ theme }) => theme.buttonBackground};
  height: 40px;
  border-radius: 4px;
  border: 0;
  outline: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: color 150ms ease, opacity 150ms ease, background 150ms ease,
    box-shadow 150ms ease;
  text-decoration: none;

  > svg {
    margin-right: ${({ theme }) => theme.spacing(0.5)};
    font-size: 24px;
  }

  &:hover {
    box-shadow: ${({ theme }) => theme.buttonBoxShadowHover};
    background: ${({ theme }) => theme.buttonBackgroundHover};
  }

  &:focus,
  &:active {
    box-shadow: ${({ theme }) => theme.buttonBoxShadowHover};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.disabled};
    color: ${({ theme }) => theme.textDisabled};
  }

  ${props =>
    props.variant === 'primary' &&
    css`
      background: ${({ theme }) => theme.primary};
      color: #fff;
      box-shadow: 0 4px 8px 0 #c2e4f2;

      &:hover {
        background: ${({ theme }) => theme.primaryLight};
        box-shadow: 0 4px 8px 0 #c2e4f2;
      }

      &:disabled,
      &:disabled:hover {
        box-shadow: 0 4px 8px 0 #c2e4f2;
        background-color: ${({ theme }) => theme.disabled};
        color: ${({ theme }) => theme.textDisabled};
        box-shadow: 0 4px 8px 0 #eee;
      }
    `};

  ${props =>
    props.variant === 'secondary' &&
    css`
      background: linear-gradient(180deg, #ffffff, #f6f7f7);
      color: ${({ theme }) => theme.text};
      box-shadow: 0 4px 8px 0 #c2e4f2;
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.separator};

      &:disabled,
      &:disabled:hover {
        box-shadow: 0 4px 8px 0 #c2e4f2;
        background-color: ${({ theme }) => theme.disabled};
        color: ${({ theme }) => theme.textDisabled};
        box-shadow: 0 4px 8px 0 #eee;
      }
    `};

  ${({ variant, theme }) =>
    variant === 'destroy' &&
    `
      background-color: ${theme.error};
      color: #fff;
    `}

  ${props =>
    props.variant === 'text' &&
    css`
      color: ${({ theme }) => theme.primary};
      padding: 0;
      background-color: transparent;
      font-weight: 500;

      &:hover {
      }

      &:disabled {
        background-color: transparent;
        color: ${({ theme }) => theme.textLight};
      }
    `};
`;

export const Button = ({
  icon,
  isLoading,
  disabled,
  children,
  to,
  ...props
}) => (
  <Wrapper
    disabled={disabled || isLoading}
    to={to}
    as={Boolean(to) && Link}
    {...props}
  >
    {isLoading && <Spinner color="currentColor" />}
    {!isLoading && icon}
    {children}
  </Wrapper>
);

export default Button;
