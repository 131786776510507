import { useState, forwardRef, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { PopoverItem, PopoverItemButton, PopoverMenu } from 'ui/lib/popover';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { get, find } from 'lodash';
import Dropdown from './Dropdown';
import Dotdotdot from 'react-dotdotdot';

const Label = styled.label`
  display: block;
  color: inherit;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  font-weight: 600;
  font-size: 1rem;
  color: ${({ theme }) => theme.text};

  ${({ disabled }) =>
    Boolean(disabled) &&
    `
      opacity: 0.5;
    `};
`;

const Trigger = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.text};
  border: 1px solid ${({ theme }) => theme.separator};
  background-color: #fff;
  border-radius: 4px;
  font-size: 1rem;
  padding: 0 ${({ theme }) => theme.spacing()};
  width: 100%;
  background: linear-gradient(180deg, #ffffff 0%, #f6f7f7 99.94%);
  height: 48px;

  ${({ disabled }) =>
    Boolean(disabled) &&
    `
      opacity: 0.5;
    `}

  ${({ hasFixedWidth }) => Boolean(hasFixedWidth) && `min-width: 260px`}

  ${({ variant }) =>
    variant === 'input' &&
    `
      background: #fff;
    `}

  ${({ hasError }) =>
    hasError &&
    css`
      &,
      &:hover,
      &:active {
        border-color: ${({ theme }) => theme.error};
      }
    `}

  svg {
    margin-left: ${({ theme }) => theme.spacing(0.5)};
  }
`;

const Value = styled(Dotdotdot)`
  ${({ isEmpty, theme }) => css`
    &,
    + svg {
      color: ${isEmpty ? theme.textLighten : theme.text};
    }
  `};
`;

const Select = forwardRef(
  (
    {
      label,
      placeholder,
      onChange,
      defaultValue,
      options,
      disabled,
      variant,
      wrapperStyle,
      value,
      hasFixedWidth,
      hasError,
    },
    ref,
  ) => {
    const [selectedValue, setSelectedValue] = useState(defaultValue);
    const dropdownRef = useRef();

    useEffect(() => {
      const selectedOption = find(options, option => option.value === value);
      setSelectedValue(selectedOption);
    }, [options, value]);

    useEffect(() => {
      if (!Boolean(selectedValue) || !onChange) {
        return;
      }

      if (value !== selectedValue.value) {
        onChange(selectedValue);
      }

      if (dropdownRef.current) {
        dropdownRef.current.close();
      }

      // eslint-disable-next-line
    }, [selectedValue]);

    return (
      <>
        {Boolean(label) && <Label disabled={disabled}>{label}</Label>}

        <Dropdown
          disabled={disabled}
          ref={dropdownRef}
          wrapperStyle={wrapperStyle}
          trigger={
            <Trigger
              disabled={disabled}
              variant={variant}
              hasFixedWidth={hasFixedWidth}
              hasError={hasError}
            >
              <Value isEmpty={!Boolean(selectedValue)} clamp={1}>
                {get(selectedValue, 'label', placeholder)}
              </Value>
              <MdKeyboardArrowDown size={24} />
            </Trigger>
          }
        >
          <PopoverMenu autoHeight autoHeightMax={300}>
            {options.map(({ value, label }, index) => (
              <PopoverItem key={index}>
                <PopoverItemButton
                  type="button"
                  onClick={() => setSelectedValue({ value, label })}
                  isActive={value === get(selectedValue, 'value')}
                >
                  {label}
                </PopoverItemButton>
              </PopoverItem>
            ))}
          </PopoverMenu>
        </Dropdown>
      </>
    );
  },
);

Select.defaultProps = {
  placeholder: '',
  options: [],
};

export default Select;
