import { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import {
  Field,
  Form as RawForm,
  ErrorMessage as RawErrorMessage,
} from 'formik';
import {
  FormField,
  FormLabel,
  Input,
  ToggleFormLabel,
  Textarea,
} from 'ui/lib/forms';
import { ButtonReset } from 'ui/lib';
import Select from './Select';
import { uniqueId } from 'lodash';
import { get } from 'lodash';
import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdVisibility,
  MdVisibilityOff,
} from 'react-icons/md';

const switchDimension = 30;
const switchBorder = 0;
const triggerSize = switchDimension - 2 * switchBorder;

export const ErrorMessageWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing(0.125)};
  color: ${({ theme }) => theme.error};
  font-size: 0.825rem;
  position: absolute;
  top: 100%;

  ${({ variant }) =>
    variant === 'static' &&
    css`
      position: static;
    `}
`;

export const ErrorMessage = props => (
  <RawErrorMessage {...props} component={ErrorMessageWrapper} />
);

const InputContainer = styled.div`
  position: relative;
  display: flex;
`;

const PasswordButton = styled(ButtonReset)`
  position: absolute;
  width: 44px;
  height: 44px;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RenderTextField = props => {
  const [isPassword] = useState(props.type === 'password');
  const [type, setType] = useState(props.type || 'text');
  const { placeholder, field, inputValue, label, form } = props;
  const hasError = Boolean(
    get(form.errors, [field.name.split('.')]) && get(form.touched, field.name),
  );

  return (
    <FormField>
      {label && <FormLabel>{label}</FormLabel>}

      <InputContainer>
        <Input
          value={inputValue}
          type={type}
          placeholder={placeholder}
          hasError={hasError}
          {...field}
          onBlur={(...args) => {
            field.onBlur(...args);
            if (props.onBlur) {
              props.onBlur(...args);
            }
          }}
        />

        {isPassword && (
          <PasswordButton
            disabled={props.disabled}
            type="button"
            onClick={() => setType(type === 'password' ? 'text' : 'password')}
            tabIndex={-1}
          >
            {type === 'text' ? <MdVisibility /> : <MdVisibilityOff />}
          </PasswordButton>
        )}

        <ErrorMessage {...field} />
      </InputContainer>
    </FormField>
  );
};

export const TextField = props => (
  <Field {...props} component={RenderTextField} />
);

const RenderTextareaField = ({ field, label, form, ...props }) => {
  const hasError = Boolean(form.errors[field.name]) && form.touched[field.name];

  return (
    <FormField>
      <FormLabel hasError={hasError}>{label}</FormLabel>
      <Textarea {...field} {...props} hasError={hasError} />
      <ErrorMessage name={field.name} />
    </FormField>
  );
};

export const TextareaField = props => {
  const [id] = useState(uniqueId());

  return <Field {...props} id={id} component={RenderTextareaField} />;
};

export const Form = styled(RawForm)``;

const ToggleSwitchWrapper = styled.div`
  display: block;
  width: ${props =>
    props.bare ? 1.75 * switchDimension : 2.5 * switchDimension}px;
  height: ${switchDimension}px;
  position: relative;
  box-shadow: inset 0 0 0 2px #f2f2f2;
  border-color: ${({ checked, theme }) =>
    checked ? theme.text : theme.textLight};
  background: ${({ theme }) => theme.disabled};
  border-radius: ${switchDimension}px;
  transition: border-color 150ms ease-out, background-color 150ms ease-out;

  [type='checkbox'] {
    display: none;
  }

  ${props =>
    props.checked &&
    css`
      background-color: ${({ theme }) => theme.primary};
      box-shadow: none;
    `};
`;

const ToggleSwitchTrigger = styled.a`
  position: absolute;
  background-color: #fff;
  top: 0;
  height: ${triggerSize}px;
  width: ${triggerSize}px;
  transition: left 150ms ease-out, background-color 150ms ease-out;
  border-radius: 100%;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  border: 0;
  left: ${props => (props.checked ? 'calc(100% - ' + triggerSize + 'px)' : 0)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  user-select: none;

  &:before {
    position: absolute;
    top: -6px;
    right: -3 * ${switchDimension}px;
    bottom: -6px;
    left: -3 * ${switchDimension}px;
    content: '';
    display: block;
  }
`;

const ToggleSwitchTrack = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #fff;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  user-select: none;
`;

export const ToggleSwitch = ({
  checked,
  onChange,
  id,
  bare,
  disabled,
  label,
  ...props
}) => (
  <>
    <span>{label}</span>

    <ToggleSwitchWrapper bare checked={checked} disabled={disabled}>
      <ToggleSwitchTrack checked={checked} disabled={disabled} />
      <ToggleSwitchTrigger checked={checked} disabled={disabled} />
    </ToggleSwitchWrapper>
  </>
);

const RenderToggleSwitch = props => {
  const { field, label, form } = props;
  const hasError = Boolean(form.errors[field.name] && form.touched[field.name]);

  return (
    <FormField as="label">
      <ToggleFormLabel>
        <input
          type="checkbox"
          {...field}
          {...props}
          style={{ display: 'none' }}
        />

        <ToggleSwitch
          {...field}
          label={label}
          checked={field.value}
          hasError={hasError}
        />
      </ToggleFormLabel>
      <ErrorMessage {...field} />
    </FormField>
  );
};

export const ToggleSwitchField = props => (
  <Field {...props} component={RenderToggleSwitch} />
);

const RenderSelectField = props => {
  const { field, inputValue, form } = props;
  const hasError = Boolean(form.errors[field.name] && form.touched[field.name]);

  useEffect(() => {
    if (!Boolean(inputValue)) {
      return;
    }
    form.setFieldValue(field.name, inputValue);
    // eslint-disable-next-line
  }, [inputValue]);

  return (
    <FormField>
      <Select
        {...props}
        hasError={hasError}
        onChange={({ value }) => form.setFieldValue(field.name, value)}
        value={field.value}
        variant="input"
      />

      <ErrorMessage {...field} />
    </FormField>
  );
};

export const SelectField = props => (
  <Field {...props} component={RenderSelectField} />
);

// const RenderSegmentedControl = props => {
//   const [selectedValues, setSelectedValues] = useState([]);
//   const [isInitialized, setIsInitialized] = useState();
//   const { field, form } = props;

//   useEffect(() => {
//     if (!isInitialized) {
//       setIsInitialized(true);
//       return;
//     }

//     form.setFieldValue(field.name, selectedValues);
//   }, [selectedValues]);

//   return (
//     <FormField>
//       <SegmentedControl
//         {...field}
//         {...props}
//         values={field.value}
//         onChange={v => setSelectedValues(v)}
//       />
//       <ErrorMessage {...field} />
//     </FormField>
//   );
// };

// export const SegmentedControlField = props => (
//   <Field {...props} component={RenderSegmentedControl} />
// );

// SegmentedControlField.defaultProps = {
//   values: [],
// };

const CheckboxContainer = styled.span`
  display: inline-block;
  display: flex;
  align-items: center;
  padding-right: ${({ theme }) => theme.spacing(0.25)};
  color: ${({ checked, theme }) => (checked ? theme.primary : 'currentColor')};
  position: relative;
  padding-right: ${({ theme }) => theme.spacing(0.5)};
  margin-top: 1px;

  &:before {
    position: absolute;
    top: 8px;
    left: 4px;
    right: 8px;
    bottom: 4px;
    z-index: -1;
    display: block;
    content: '';
  }
  &:hover:before {
    transition: background-color 100ms ease;
    background-color: #f4f2ef;
  }
`;

const CheckboxLabel = styled.label`
  cursor: pointer;
  display: flex;
  user-select: none;
  color: currentColor;
  align-items: flex-start;

  [type='checkbox'] {
    display: none;
  }

  svg {
    height: 24px;
  }
`;

export const Checkbox = ({
  checked,
  label,
  onChange,
  dark,
  checkboxColor,
  name,
}) => (
  <CheckboxLabel checked={checked} dark={dark}>
    <input type="checkbox" checked={checked} onChange={onChange} name={name} />

    <CheckboxContainer checked={checked} checkboxColor={checkboxColor}>
      {checked && <MdCheckBox />}
      {!checked && <MdCheckBoxOutlineBlank />}
    </CheckboxContainer>
    {label}
  </CheckboxLabel>
);

const RenderCheckbox = ({ field, form, ...props }) => {
  const handleChange = (...args) => {
    form.setFieldTouched(field.name, true);
    setTimeout(() => form.setFieldValue(field.name, !field.checked));
  };

  return (
    <FormField>
      <Checkbox {...props} {...field} onChange={handleChange} />
      <ErrorMessage name={field.name} />
    </FormField>
  );
};
export const CheckboxField = props => (
  <Field {...props} type="checkbox" component={RenderCheckbox} />
);

export const DatePickerWrapper = styled.div`
  .react-date-picker__wrapper {
    width: 25em;
    border: 1px solid ${({ theme }) => theme.separator};
    border-radius: 8px;
    height: 48px;
    padding: ${({ theme }) => theme.spacing(0.5)};
  }

  .react-date-picker__inputGroup {
    flex: 1;
  }

  ${({ size }) =>
    size === 'full' &&
    css`
      .react-date-picker,
      .react-date-picker__wrapper {
        width: 100%;
      }
    `}

  ${({ hasError }) =>
    hasError &&
    css`
      .react-date-picker__wrapper {
        border: 1px solid ${({ theme }) => theme.error};
      }
    `}
`;
