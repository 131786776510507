import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import { get, pickBy } from 'lodash';
import queryString from 'query-string';
import { BIC } from 'ibankit';

export const capitalize = (input = '') => {
  if (!Boolean(input)) {
    return '';
  }
  if (input.toLowerCase() === input) {
    return input
      .replace(/(?:^|[\s-/.'])\w/g, match => match.toUpperCase())
      .replace(/\s[a-zA-Z{1,2}]\s/g, match => match.toLowerCase());
  }
  if (input.toUpperCase() === input) {
    return input
      .toLowerCase()
      .replace(/(?:^|[\s-/.'])[\wéàèáùòóôûêâ]/g, match => match.toUpperCase())
      .replace(/\s[a-zA-Z{1,2}]\s/g, match => match.toLowerCase());
  }
  return input;
};

export const formatDate = (date, frmt) => format(date, frmt, { locale: fr });

export const getParam = (location, param) =>
  get(queryString.parse(location.search), param);

export const getAttachmentURL = (url = '') => {
  const [uri, search] = (url || '').split('?');
  const enrichedSearch = queryString.stringify({
    ...queryString.parse(search),
    access_token: localStorage.getItem('accessToken'),
  });

  return (url || '').indexOf(';base64') > -1 ? url : `${uri}?${enrichedSearch}`;
};

export const overridePage = (search, page) =>
  queryString.stringify(
    pickBy({
      ...queryString.parse(search),
      page: page === 1 ? null : page,
    }),
  );

export const overrideParam = (search, param) =>
  queryString.stringify(
    pickBy({
      ...queryString.parse(search),
      ...param,
    }),
  );

export const getContestationReason = contestationReason => {
  switch (contestationReason) {
    case 'do_deposit':
      return 'Acompte à faire';
    case 'service_contest':
      return 'Contestation prestation';
    case 'contract_canceled':
      return 'Contrat résilié';
    case 'invoice_amount_improper':
      return 'Montant facture non conforme';
    case 'accountant_rejection':
      return 'Rejet comptable';
    case 'waiting_work':
      return 'Attente réception travaux';
    case 'error_vat_provider':
      return 'Erreur TVA prestataire';
    case 'place_lost':
      return 'Immeuble perdu';
    case 'imputation_error_without_rejection':
      return "Erreur d'imputation sans rejet";

    default:
      return '';
  }
};

export const ERRORS = {
  REQUIRED: 'Champ requis',
  TAKEN: 'Valeur déjà prise',
  INVALID: 'Valeur invalide',
  EMAIL: 'Adresse mail invalide',
  PASSWORDS_DO_NOT_MATCH: 'Les mots de passe ne concordent pas',
  BAD_CHECK_DIGITS: 'IBAN incorrect',
  NUMBER: 'Ce champ doit être un nombre',
  NOT_APPROVED: 'Ce fournisseur n’est pas validé',
  CANNOT_APPROVE_IF_BLANK: 'Champ requis',
};

export const formatAmount = (amount, shouldShowPlusSign) => {
  if (typeof Intl === 'undefined') {
    return `${amount > 0 ? '+' : ''}${amount.toFixed(2)}\u00a0€`;
  }

  return `${amount > 0 ? '+' : ''}${new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
  }).format(amount)}`;
};

export const validateBIC = bic => {
  if (bic.length > 13) {
    return 'BIC trop long';
  } else if (bic.length < 8) {
    return 'BIC trop court';
  } else if (!BIC.isValid(bic)) {
    return 'BIC invalide';
  }
};
