import styled from 'styled-components';
import RawLoader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
`;

const Loader = () => (
  <Wrapper>
    <RawLoader
      visible={true}
      type="Oval"
      color="#009de0"
      height={60}
      width={60}
      timeout={3000}
    />
  </Wrapper>
);

export default Loader;
