import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Tabs, Tab, TabNavLink } from 'components/lib';
import { Container, ButtonReset } from 'ui/lib';
import { useAuth } from 'containers/AuthProvider';
import { useApp } from 'containers/AppProvider';

const Wrapper = styled.header`
  background-color: #fff;
  height: 88px;
  display: flex;
  align-items: stretch;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
`;

const Inner = styled.nav`
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  align-items: center;

  > * {
    flex: 1;
  }
`;

const Logo = styled(Link)`
  font-size: 20px;
  font-weight: bold;
  color: inherit;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
`;

const Logout = styled(ButtonReset)`
  color: ${({ theme }) => theme.primary};
  border: 0;
  font-size: 1rem;
  display: flex;
  justify-content: flex-end;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const MainHeader = () => {
  const { logout } = useAuth();
  const { counters } = useApp();

  return (
    <Wrapper>
      <Container>
        <Inner>
          <Logo to="/">Factures fournisseurs</Logo>

          <Tabs alignment="center">
            <Tab>
              <TabNavLink to="/facturation">
                Facturation
                {Boolean(counters.ape) && ` • ${counters.ape}`}
              </TabNavLink>
            </Tab>

            <Tab>
              <TabNavLink to="/fournisseurs">
                Fournisseurs
                {Boolean(counters.providers) && ` • ${counters.providers}`}
              </TabNavLink>
            </Tab>
          </Tabs>

          <Actions>
            <Logout onClick={logout}>Se déconnecter</Logout>
          </Actions>
        </Inner>
      </Container>
    </Wrapper>
  );
};

export default MainHeader;
