import styled, { css } from 'styled-components';
import { Pills, PillItem, PillButton } from './Pills';
import { Stack } from '@tymate/margaret';

const Segments = styled(Pills)`
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.separator};
  border-radius: ${({ theme }) => theme.borderRadius};
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(${({ optionsCount }) => optionsCount}, 1fr);
`;

export const SegmentedItem = styled(PillItem)`
  box-shadow: inset -1px 0 0 0 ${({ theme }) => theme.separator};
  flex: 1;

  + li {
    margin-left: -1px !important;
  }

  &:last-child {
    box-shadow: none;
  }
`;

const SegmentedButton = styled(PillButton)`
  border-radius: 0;
  box-shadow: none;
  width: 100%;

  &:hover {
    opacity: 0.8;
  }

  ${({ variant }) =>
    variant === 'oneLiner' &&
    css`
      white-space: nowrap;
    `};

  &.success {
    color: ${({ theme }) => theme.success};

    &.active {
      color: #fff;
      background-color: ${({ theme }) => theme.success};
    }

    ${({ isActive }) =>
      isActive &&
      css`
        color: #fff;
        background-color: ${({ theme }) => theme.success};
      `};
  }

  &.error {
    color: ${({ theme }) => theme.error};

    &.active {
      color: #fff;
      background-color: ${({ theme }) => theme.error};
    }

    ${({ isActive }) =>
      isActive &&
      css`
        color: #fff;
        background-color: ${({ theme }) => theme.error};
      `};
  }
`;

const SegmentedControls = ({
  values,
  activeValue,
  activeBackgroundColor,
  onSelect,
  onlyDisplaysIcons,
  label,
  variant,
  size,
  ...props
}) => (
  <Stack size="full">
    <Segments
      gutterSize={0}
      size={size}
      optionsCount={values.length}
      {...props}
    >
      {values.map(
        ({ value, label, alternateActiveValues = [], variant, cls }, key) => (
          <SegmentedItem key={key}>
            <SegmentedButton
              activeBackgroundColor={activeBackgroundColor}
              onlyDisplaysIcons={onlyDisplaysIcons}
              className={
                value === activeValue ||
                alternateActiveValues.indexOf(activeValue) > -1
                  ? cls + ' ' + 'active'
                  : cls || ''
              }
              onClick={() => onSelect(value)}
              variant={variant}
              type="button"
            >
              {label}
            </SegmentedButton>
          </SegmentedItem>
        ),
      )}
    </Segments>
  </Stack>
);

SegmentedControls.defaultProps = {
  values: [],
  onSelect: () => null,
  size: 'full',
};

export default SegmentedControls;
