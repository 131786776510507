import styled, { css } from 'styled-components';
import { ButtonReset } from './';
import Scrollbars from 'react-custom-scrollbars';

export const PopoverContainer = styled.div`
  position: relative;
`;

export const PopoverMenuInner = styled(Scrollbars)``;

export const PopoverMenu = styled.div`
  position: absolute;
  left: 0;
  top: calc(100% + ${({ theme }) => theme.spacing(0.25)});
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
  min-width: 100%;
  width: 200px;
  background-color: #fff;

  ${({ alignment }) =>
    alignment === 'right' &&
    `
      left: auto;
      right: 0
      `};
`;

export const PopoverItemButton = styled(ButtonReset)`
  display: flex;
  text-align: center;
  text-decoration: none;
  padding: ${({ theme }) => theme.spacing(0.75)}
    ${({ theme }) => theme.spacing()};
  cursor: pointer;
  text-align: left;
  line-height: 1.1;
  font-size: 1rem;
  color: ${({ theme }) => theme.textLight};
  display: flex;
  align-items: center;
  svg {
    margin-right: ${({ theme }) => theme.spacing(0.5)};
  }

  &:hover {
    background-color: ${({ theme }) => theme.primaryBackgroundLight};
  }

  ${props =>
    props.isActive &&
    css`
      background-color: ${({ theme }) => theme.primaryBackground};
    `}
`;

export const PopoverItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PopoverFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(0.5)};
`;
