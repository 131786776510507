import { useEffect } from 'react';
import { useAuth } from './AuthProvider';
import { history } from 'index';
import styled, { css } from 'styled-components';
import { spacing, media, Buttons } from 'ui/lib';
import background from 'images/background.jpg';
import logo from 'images/logo.svg';
import { Formik, Form } from 'formik';
import { TextField } from 'components/lib/Form';
import { Button } from '@tymate/sergic';
import { API_BASE, CLIENT_ID } from '../constants';
import { camelizeKeys } from 'humps';

const login = async payload => {
  const response = await fetch(`${API_BASE}/v1/oauth/token`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...payload,
      client_id: CLIENT_ID,
      grant_type: 'password',
      scope: 'syndicone admin',
    }),
  });

  if (!response.ok) {
    throw Error({
      status: response.status,
      message: response.statusText,
    });
  }

  return response.json();
};

export const refreshToken = async token => {
  const response = await fetch(`${API_BASE}/v1/oauth/token`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      refresh_token: token,
      client_id: CLIENT_ID,
      grant_type: 'refresh_token',
    }),
  });

  if (!response.ok) {
    return { error: true };
  }

  return response.json();
};

export const Sidebar = styled.div`
  background-color: #fff;
  position: relative;
  width: 100%;

  ${media.tablet`
    min-height: 100%;
    flex: 0 0 300px;
    border-right: 1px solid ${({ theme }) => theme.separator};
  `};

  ${props =>
    props.multi &&
    props.one &&
    css`
      position: relative;
      z-index: 2;
      box-shadow: none;
    `};

  ${props =>
    props.two &&
    css`
      ${media.tablet`flex: 0 0 300px;`};
    `};

  ${props =>
    props.isLarge &&
    css`
      ${media.tablet`min-width: 300px;`};
      ${media.desktop`min-width: 420px;`};
    `};
`;

export const SecondWrapper = styled.div`
  ${media.tablet`
    display: flex;
    align-items: stretch;
  `};
`;

export const Container = styled.div`
  padding: ${spacing(2)};
`;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  min-height: 100vh;
  position: relative;
  z-index: 1;
`;

export const Background = styled.div`
  background-image: url(${background});
  flex: 1;
  background-size: cover;
  background-position: center center;
`;

export const Logo = () => (
  <img
    src={logo}
    alt="sergic"
    style={{ marginBottom: spacing(2), width: 180 }}
  />
);

const Auth = () => {
  const { isLoggedIn, onReceiveToken } = useAuth();

  const handleLogin = async values => {
    try {
      const result = await login(values);
      const { accessToken, refreshToken } = camelizeKeys(result);
      onReceiveToken({ accessToken, refreshToken });
    } catch (err) {}
  };

  useEffect(() => {
    if (isLoggedIn) {
      history.replace('/');
    }
  }, [isLoggedIn]);

  return (
    <Wrapper>
      <SecondWrapper style={{ minHeight: '100vh' }}>
        <Sidebar style={{ flex: '0 0 600px' }}>
          <Container hasVerticalPadding style={{ minHeight: '100vh' }}>
            <Logo />

            <Formik
              initialValues={{ username: '', password: '' }}
              onSubmit={handleLogin}
            >
              {({ handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <TextField label="Email" name="username" />

                  <TextField
                    label="Mot de passe"
                    name="password"
                    type="password"
                  />

                  <Buttons alignment="right">
                    <Button
                      variant="primary"
                      isLoading={isSubmitting}
                      type="submit"
                    >
                      Se connecter
                    </Button>
                  </Buttons>
                </Form>
              )}
            </Formik>
          </Container>
        </Sidebar>
        <Background />
      </SecondWrapper>
    </Wrapper>
  );
};

export default Auth;
