import { spacing, ButtonReset } from 'ui/lib';
import styled from 'styled-components';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { Link } from 'react-router-dom';

export const theme = {
  primary: '#009de0',
  primaryBackground: '#e1f2fa',
  primaryBackgroundLight: '#ebf8fe',

  text: 'rgba(0, 0, 0, 0.87)',
  textLight: 'rgba(0, 0, 0, 0.60)',

  error: '#E22A2A',
  background: '#f4f4f4',
  separator: 'rgba(0,0,0,0.12)',
  borderRadius: '6px',
  spacing,
};

export const Content = styled.div`
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};

  ${({ paddingTopSize, theme }) =>
    paddingTopSize === 'small' &&
    `
      padding-top: ${theme.spacing()};
    `}
`;

const PrevNextButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.spacing(-0.5)};
  font-size: 14px;

  > * {
    margin-left: ${({ theme }) => theme.spacing(0.5)};
  }
`;

const PrevNextButton = styled(ButtonReset)`
  background-color: rgba(0, 157, 224, 0.08);
  border-radius: 100%;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PrevButton = ({ disabled, onClick }) => (
  <PrevNextButtonWrapper>
    <PrevNextButton disabled={disabled} onClick={onClick}>
      <MdChevronLeft size={22} />
    </PrevNextButton>
    <span>PRÉC</span>
  </PrevNextButtonWrapper>
);

export const NextButton = ({ disabled, onClick }) => (
  <PrevNextButtonWrapper>
    <span>SUIV</span>
    <PrevNextButton disabled={disabled} onClick={onClick}>
      <MdChevronRight size={22} />
    </PrevNextButton>
  </PrevNextButtonWrapper>
);

export const RootLink = styled(Link)`
  color: inherit;
  margin-bottom: ${({ theme }) => theme.spacing()};
  text-decoration: none;
  font-weight: 500;
  display: flex;
  align-items: center;
`;
