import styled, { css } from 'styled-components';
import { Link as RawLink } from 'react-router-dom';
import { MdChevronRight, MdChevronLeft } from 'react-icons/md';
import { List } from 'ui/lib';
import { overridePage } from 'utils/lib';
import { useLocation } from 'react-use';

const Wrapper = styled(List)`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica Neue, Helvetica, Arial, sans-serif;
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing()};
  bottom: 0;
  z-index: 1;
`;

const Item = styled.li`
  display: inline-block;

  + li {
    margin-left: ${({ theme }) => theme.spacing(0.5)};
  }

  ${props =>
    props.ellipsis &&
    css`
      padding-left: ${({ theme }) => theme.spacing()};
      padding-right: ${({ theme }) => theme.spacing()};
    `};
`;

const Link = styled(RawLink)`
  padding: ${({ theme }) => theme.spacing(0.5)};
  min-width: 2em;
  color: inherit;
  display: block;
  transition: background-color 150ms ease;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    border-radius: 4px;
  }
`;

const NonLink = styled.span`
  padding: ${({ theme }) => theme.spacing(0.5)};
  min-width: 2em;
  display: block;
  color: ${({ theme }) => theme.separator};
  white-space: nowrap;
`;

const ActivePage = styled(NonLink)`
  background-color: ${({ theme }) => theme.primary};
  border-radius: 4px;
  color: #fff;
`;

const Pagination = ({ currentPage, totalPages }) => {
  const { pathname, search } = useLocation();

  if (!totalPages || totalPages === 1) {
    return null;
  }

  return (
    <Wrapper>
      <Item>
        {currentPage > 1 ? (
          <Link
            to={{ pathname, search: overridePage(search, currentPage - 1) }}
          >
            <MdChevronLeft />
          </Link>
        ) : (
          <NonLink>
            <MdChevronLeft />
          </NonLink>
        )}
      </Item>
      {currentPage !== 1 && (
        <Item>
          <Link to={{ pathname, search: overridePage(search, 1) }}>1</Link>
        </Item>
      )}
      {currentPage > 4 && <Item ellipsis>…</Item>}
      {currentPage > 3 && (
        <Item>
          <Link
            to={{ pathname, search: overridePage(search, currentPage - 2) }}
          >
            {currentPage - 2}
          </Link>
        </Item>
      )}
      {currentPage > 2 && (
        <Item>
          <Link
            to={{ pathname, search: overridePage(search, currentPage - 1) }}
          >
            {currentPage - 1}
          </Link>
        </Item>
      )}

      <Item>
        <ActivePage>{currentPage}</ActivePage>
      </Item>

      {currentPage + 1 < totalPages && (
        <Item>
          <Link
            to={{ pathname, search: overridePage(search, currentPage + 1) }}
          >
            {currentPage + 1}
          </Link>
        </Item>
      )}
      {currentPage + 2 < totalPages && (
        <Item>
          <Link
            to={{ pathname, search: overridePage(search, currentPage + 2) }}
          >
            {currentPage + 2}
          </Link>
        </Item>
      )}
      {currentPage + 3 < totalPages && <Item ellipsis>…</Item>}
      {currentPage !== totalPages && (
        <Item>
          <Link to={{ pathname, search: overridePage(search, totalPages) }}>
            {totalPages}
          </Link>
        </Item>
      )}
      <Item>
        {currentPage < totalPages ? (
          <Link
            to={{ pathname, search: overridePage(search, currentPage + 1) }}
          >
            <MdChevronRight />
          </Link>
        ) : (
          <NonLink>
            <MdChevronRight />
          </NonLink>
        )}
      </Item>
    </Wrapper>
  );
};

export default Pagination;
