import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { InlineList, NavLink } from 'ui/lib';

export const Tabs = styled(InlineList)`
  flex-wrap: nowrap;
  max-width: 100vw;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  align-self: stretch;

  ${({ nested, theme }) =>
    nested &&
    `
      padding: 0 ${theme.spacing()};
      margin-left: ${theme.spacing(-1)};
      margin-right: ${theme.spacing(-1)};
    `};

  ${({ alignment }) =>
    alignment === 'center' &&
    `
      display: flex;
      justify-content: center;
    `}
`;

export const Tab = styled.li`
  + li {
    margin-left: ${({ theme }) => theme.spacing()};
  }
`;

export const TabNavLink = styled(NavLink)`
  color: inherit;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing()} 0;
  font-weight: 500;
  color: ${({ theme }) => theme.textLight};
  text-decoration: none;
  transition: color 150ms ease;
  height: 100%;

  &:hover,
  &.active {
    color: ${({ theme }) => theme.text};
  }

  &.active {
    box-shadow: inset 0 -3px 0 0 ${({ theme }) => theme.primary};
  }
`;

export const TabLink = TabNavLink.withComponent(Link);
