import { createContext, useContext, useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error(`useAuth must be used within an AuthProvider`);
  }

  return context;
};

const AuthProvider = ({ children }) => {
  const client = useApolloClient();
  const history = useHistory();

  const [accessToken, setAccessToken] = useState(
    localStorage.getItem('accessToken'),
  );

  const handleReceiveToken = ({ accessToken, refreshToken }) => {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    setAccessToken(accessToken);
    history.push('/');
  };

  const handleReset = () => {
    localStorage.clear();
    setAccessToken(null);
    client.clearStore();
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: Boolean(accessToken),
        logout: handleReset,
        onReceiveToken: handleReceiveToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
