import { round } from 'lodash';

export const base = 16;
export const spacing = (input = 1) => `${input * base}px`;
export const size = (input = 0) =>
  `${round(Math.sqrt(Math.sqrt(2)) ** input, 3)}rem`;

export const theme = {
  primary: '#009DE0',
  primaryLight: '#00AFFA',
  primaryDark: '#002B40',
  primaryBackground: '#E1F2FA',
  primaryBackgroundLight: '#ebf8fe',

  text: 'rgba(0, 0, 0, 0.87)',
  textLight: 'hsl(210, 15%, 45%)',
  textDisabled: 'rgba(0, 0, 0, 0.38)',
  textLightish: 'hsl(210, 15%, 65%)',
  textLighter: 'rgba(25, 75, 105, 0.3)',
  textOnPrimary: '#ffffff',

  // success: color('green'),
  // error: color('red', 'medium'),
  // warning: color('orange', 'dark'),

  separator: 'rgba(0, 0, 0, 0.24)',

  background: '#f4f4f4',
  backgroundLight: 'hsl(210, 15%, 97%)',
  disabled: '#eeeeee',
  sidebarMenuActiveLinkBackgroundColor: 'rgba(0, 0, 0, 0.08)',

  buttonColor: 'inherit',
  buttonBackground: 'linear-gradient(180deg, #FFFFFF 0%, #F6F7F7 100%)',
  buttonBoxShadow: 'inset 0 0 0 1px rgba(0, 0, 0, 0.12)',
  buttonBoxShadowHover: 'inset 0 0 0 1px rgba(0, 0, 0, 0.12)',
  buttonBoxShadowFocus: 'inset 0 0 0 1px rgba(0, 0, 0, 0.12)',
  buttonBackgroundHover: 'linear-gradient(180deg, #FFFFFF 0%, #F6F7F7 100%)',

  borderRadius: '6px',

  spacing,
  size,
};
