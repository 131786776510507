import styled, { css } from 'styled-components';
import { Button as MgtButtton } from '@tymate/margaret';
import tinycolor from 'tinycolor2';
import MDSpinner from 'react-md-spinner';

export const Button = styled(MgtButtton)`
  height: 40px;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.75)};
  font-weight: 400;
  border-radius:${({ theme }) => theme.borderRadius};
  text-decoration: none;
  display: flex;
  align-items: center;

  > svg {
    margin-right: ${({ theme }) => theme.spacing(0.25)};
  }

  > * + svg {
    margin-left: ${({ theme }) => theme.spacing(0.25)};
  }

  ${({ variant }) =>
    !variant &&
    css`
      background-image: linear-gradient(to bottom, #ffffff, #f6f7f7);
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.separator};
      color: ${({ theme }) => theme.text};

      &:hover {
        background-image: linear-gradient(to bottom, #f6f7f7, #f0f1f1);
        color: ${({ theme }) => theme.text};
      }

      &:disabled {
        color: ${({ theme }) => theme.textLight};
        background-image: none;
      }
    `}

  ${({ variant }) =>
    variant === 'primary' &&
    css`
      box-shadow: 0 4px 8px 0
        ${({ theme }) =>
          tinycolor(theme.primary)
            .setAlpha(0.3)
            .toString()};

      &:not([disabled]):hover {
        background-color: ${({ theme }) =>
          tinycolor(theme.primary)
            .setAlpha(0.8)
            .toString()};
        color: #fff;
      }

      &:disabled,
      &:disabled:hover {
        background-color: ${({ theme }) => theme.disabledBackground};
      }
    `}

  ${({ variant }) =>
    variant === 'text' &&
    css`
      background: transparent;
      border: 0;
      box-shadow: none;
      color: ${({ theme }) => theme.primary};
      padding-left: 0;
      padding-right: 0;
      justify-content: flex-start;
      align-items: center;

      &:not([disabled]):hover {
        background-color: transparent;
        color: ${({ theme }) =>
          tinycolor(theme.primary)
            .setAlpha(0.8)
            .toString()};
      }
    `}

  ${({ variant }) =>
    variant === 'danger' &&
    css`
      background-color: ${({ theme }) => theme.error};
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
      color: #fff;

      &:not([disabled]):hover {
        background-color: ${({ theme }) =>
          tinycolor(theme.error)
            .setAlpha(0.8)
            .toString()};
        color: #fff;
      }
    `}

  ${({ variant }) =>
    variant === 'pagination' &&
    css`
      font-weight: 500;
      color: ${({ theme }) => theme.textLight};
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.separator};
      background-color: #fff;

      &:not([disabled]):hover {
        color: ${({ theme }) =>
          tinycolor(theme.textLight)
            .setAlpha(0.6)
            .toString()};
      }

      ${({ isDisabled }) =>
        isDisabled &&
        css`
          &:hover {
            color: ${({ theme }) => theme.textLight};
            background: #fff;
          }
        `}
    `}

  ${({ variant, isActive }) =>
    variant === 'pagination' &&
    isActive &&
    css`
      &:disabled,
      &:disabled:hover {
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.separator};
        background-color: #fff;
        color: ${({ theme }) => theme.primary};
      }
    `}

  ${({ variant }) =>
    variant === 'suffix' &&
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-image: linear-gradient(to bottom, #ffffff, #f6f7f7);
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.separator};
      color: ${({ theme }) => theme.text};
      border-radius: 0 4px 4px 0;
      margin-left: -1px;

      &:hover {
        background-image: linear-gradient(to bottom, #f6f7f7, #f0f1f1);
        color: ${({ theme }) => theme.text};
      }
    `}

  [class*="loader"] {
    transform: scale(0.8) translateX(${({ theme }) =>
      theme.spacing()}) translateY(2px);
    margin-left: ${({ theme }) => theme.spacing(0.5)};
  }

  ${({ size }) =>
    size === 'small' &&
    css`
      height: 28px;
      font-size: ${({ theme }) => theme.size(-1)};
      padding: ${({ theme }) => theme.spacing(0.25)}
        ${({ theme }) => theme.spacing(0.5)};

      [class*='loader'] {
        transform: scale(0.6) translateX(${({ theme }) => theme.spacing(2)})
          translateY(6px);
      }

      ${({ variant }) =>
        variant === 'text' &&
        css`
          padding-left: 0;
          padding-right: 0;
        `}
    `}

    &:disabled {
      cursor: default;
    }
`;

export const LoadingButton = ({ isLoading, children, ...props }) => (
  <Button {...props}>
    {isLoading && (
      <MDSpinner
        style={{ marginRight: 8 }}
        singleColor="currentColor"
        size={16}
      />
    )}
    {children}
  </Button>
);

export default Button;
