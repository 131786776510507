import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import { InlineList, media } from 'ui/lib';

export const Pill = styled.li``;

export const PillNavLink = styled(NavLink)`
  color: inherit;
  display: inline-block;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.875)};
  border-radius: 4px;
  font-weight: 400;
  color: ${({ theme }) => theme.textLight};
  text-decoration: none;
  transition: background-color 150ms ease, color 150ms ease;

  &:hover {
    background-color: #eaf3f5;
    color: ${({ theme }) => theme.text};
  }

  ${({ isActive, theme }) =>
    isActive &&
    `
      background-color: ${theme.primary};
      color: #fff;

      &:hover {
        background-color: ${theme.primary};
        color: #fff;
      }
    `};

  &.active {
    background-color: ${({ theme }) => theme.primary};
    color: #fff;

    &:hover {
      background-color: ${({ theme }) => theme.primary};
      color: #fff;
    }
  }
`;

export const PillLink = PillNavLink.withComponent(Link);

export const Pills = styled(InlineList)`
  justify-content: flex-start;
  background-color: #fff;
  flex-wrap: nowrap;
  max-width: 100vw;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;

  ${media.desktop`
    flex-wrap: wrap;
    justify-content: flex-start;
    box-shadow: none;
  `};

  > li + li {
    margin-left: ${({ theme }) => theme.spacing(0.5)};
  }

  ${({ variant, theme }) =>
    variant === 'alt' &&
    `
      background-color: transparent;

      ${PillNavLink}:hover {
        background-color: #e6eaef;
      }

      ${PillNavLink}.active:hover {
        background-color: ${theme.primary};
      }
    `};
`;
