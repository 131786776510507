import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { size } from './';

export const Table = styled.div`
  width: 100%;
  display: table;
  font-size: 14px;
  border-radius: 4px;

  ${props =>
    props.variant === 'bordered' &&
    css`
      background-color: #fff;
      border: 1px solid ${({ theme }) => theme.separator};
    `};
`;

export const Cell = styled.div`
  display: table-cell;

  ${props => props.full && `width: 100%;`};
`;

export const Thead = styled.div`
  display: table-header-group;
`;

export const Tbody = styled.div`
  display: table-row-group;
`;

export const Th = styled.div`
  display: table-cell;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()};
  text-align: left;
  ${'' /* font-size: ${({ theme }) => theme.size(-1)}; */}
  vertical-align: middle;

  button {
    font-weight: inherit;
  }

  span {
    display: inline-block;
  }

  + div {
    padding-left: 0;
  }

  ${props =>
    props.hasNoHorizontalPadding &&
    css`
      padding-left: 0;
    `};
`;

const trStyles = `
  display: table-row;
  position: relative;

  svg {
    font-size: ${size(2)};
  }
`;

const LinkTr = styled(Link)`
  ${trStyles} text-decoration: none;
  color: inherit;

  &.bold {
    font-weight: bold;
  }

  &:hover {
    background-color: ${({ theme }) => theme.background};
  }
`;

const DefaultTr = styled.div`
  ${trStyles};

  &.active:hover {
    background-color: ${({ theme }) => theme.background};
    cursor: pointer;
  }

  a.bold {
    font-weight: bold;
  }
`;

export const Tr = props =>
  Boolean(props.to) ? <LinkTr {...props} /> : <DefaultTr {...props} />;

export const Td = styled.div`
  display: table-cell;
  vertical-align: middle;
  padding: ${({ theme }) => theme.spacing(0.75)}
    ${({ theme }) => theme.spacing()};
  border-top: 1px solid ${({ theme }) => theme.separator};

  + div {
    padding-left: 0;
  }

  ${props =>
    props.hasNoHorizontalPadding &&
    css`
      padding-left: 0;
      padding-right: 0;
    `};

  ${props =>
    props.hasNoLeftPadding &&
    css`
      padding-left: 0;
    `};
`;
